@use '/src/assets/new_variables.scss' as *;

#united {
  position: relative;
  margin: 0px 45px 0px 45px;
  z-index: 99;
  .show_more_text {
    color: $color-chambray;
    position: absolute;
    top: 16px;
    right: 30px;
    text-decoration: underline;
    font-weight: 600;
    background: $color-white;
    font-family: $font-secondry;
    cursor: pointer;
  }

  #newsTicker {
    height: 60px;
    overflow: hidden;
    display: block;
    margin-top: -30px;
  }
  .news_component {
    overflow: hidden;
    position: absolute;
    transition: height 0.5s;
    width: 100%;
  }
  .news_component.open {
    cursor: pointer;
    width: 100%;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    border-radius: 0px 0px 8px 8px;
  }

  .news_bar_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $color-white;
    border-radius: 8px;
    padding-left: 21px;
    padding-right: 30px;
    position: relative;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 100%;
    transition: height 0.3s;
    overflow: hidden;
    .line_container {
      display: flex;
      align-items: center;
      width: 100%;
      font-weight: 500;
      height: 60px;
      transition: all 1s;
      font-family: $font-secondry;
      color: unset;
      text-decoration: none;
      .show_more {
        color: $color-chambray;
        text-decoration: underline;
        margin-left: auto;
        cursor: pointer;
      }
      .date {
        color: $color-hoki;
        text-wrap: nowrap;
        font-size: 20px;
        font-weight: 600;
        height: 26px;
        width: 110px; // this rule is snice otherwise dates not aligned alltogether
        white-space: nowrap;
      }
      .line {
        background: $color-deep-graphite;
        width: 1px;
        height: 12px;
        margin-left: 16px;
        margin-right: 18px;
        margin-top: 4px;
      }
      .title {
        font-size: 20px;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
        cursor: pointer;
        color: unset;
        text-decoration: none;
      }
    }
    .line_container.open:hover {
      background: $color-link-water;
      width: calc(100% + 60px);
      padding-left: 30px;
    }
    .line_container_see_more {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      font-size: 20px;
      font-weight: 600;
      color: $color-hoki;
      .see_more_link {
        text-decoration: underline;
        color: $color-chambray;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        font-family: $font-secondry;
        align-items: center;
        // width: 170px;
        justify-content: space-between;
        white-space: nowrap;
        .arrow {
          background: url('arrow.png');
          width: 24px;
          height: 26px;
          position: relative;
          right: 0px;
          transition: right ease 0.5s;
          background-repeat: no-repeat;
          margin-left: 10px;
        }
        .arrow:hover {
          right: -10px;
        }
      }
    }
  }

  .news_bar_container.open {
    border-radius: 0px 0px 8px 8px;
  }

  .news_bar_container.bottom.close {
    max-height: 0;
    opacity: 0;
    transition: all 0.15s ease-out;
    overflow: hidden;
  }

  .news_bar_container.bottom.open {
    max-height: 700px;
    opacity: 1;
    transition: all 0.25s ease-in;
    z-index: 1;
  }

  .news_bar_container.open .line_container:first-child {
    border-top: solid 3px $color-link-water;
    transition: all 1s, border 0s;
  }
  #newsTicker .line_container:first-child {
    border-top: unset;
  }
  .news_bar_container {
    &.top {
      &.open {
        border-radius: 8px 8px 0px 0px;
        .line_container {
          .title {
            @media screen and (max-width: 1850px) {
              width: 85%;
            }
            @media screen and (max-width: 1600px) {
              width: 82%;
            }
            @media screen and (max-width: 1400px) {
              width: 79.5%;
            }
          }
        }
      }
    }
  }

  .news_bar_container {
    &.bottom {
      &.open {
        .line_container:hover {
          background: $color-link-water;
          width: calc(100% + 60px);
          padding-left: 30px;
          @media screen and (min-width: 900px) and (max-width: 1281px) {
            padding-left: 0px;
            width: calc(100% + 25px);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #united {
    margin: -160px 0px 50px 0px;
    .arrow {
      background: url('arrow.png');
      background-repeat: no-repeat;
      height: 19px;
      width: 23px;
      position: absolute;
      right: 10px;
      top: 20px;
    }
    .news_bar_container {
      border-radius: unset;
      padding-left: 10px;
      .line_container {
        .title {
          font-size: 16px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: unset;
          flex: 7;
          line-height: 27px;
        }
        .line {
          margin-left: 7px;
          margin-right: 7px;
          height: 15px;
          margin-top: unset;
        }
        .date {
          font-size: 16px;
          width: unset;
          white-space: nowrap;
          margin-top: 4px;
          flex: 1;
        }
      }
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1280px) {
  #united {
    .news_component {
      .news_bar_container {
        .line_container_see_more {
          .see_more_link {
            .arrow {
              top: 4px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1280px) {
  #united {
    margin: 0px;
    padding-right: 10px;
    padding-left: 10px;
    .arrow {
      background: url('arrow.png');
      position: absolute;
      right: 15px;
      top: 15px;
      background-repeat: no-repeat;
      margin-left: auto;
      margin-right: 2px;
      width: 26px;
      display: flex;
      height: 24px;
    }
  }
  #news_component {
    position: relative;
  }

  #newsTicker {
    height: 60px;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
  }

  .news_bar_container {
    width: 100%;
    .line_container {
      .title {
        font-size: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: unset;
        flex: 7;
        line-height: 27px;
      }
      .line {
        margin-left: 6px;
        margin-right: 8px;
        height: 15px;
        margin-top: unset;
      }
      .date {
        font-size: 16px;
        width: unset;
        white-space: nowrap;
        margin-top: 0px;
        flex: unset;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  #united {
    margin: 0px;
    .arrow {
      background: url('arrow.png');
      position: absolute;
      right: 7px;
      top: 16px;
      background-repeat: no-repeat;
      margin-left: auto;
      margin-right: 2px;
      width: 26px;
      display: flex;
      height: 24px;
    }
  }
}
